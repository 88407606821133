import React, { useEffect } from 'react';
import Layout from '@/layouts';
import Head from 'next/head';
import ErrorPage from '@/components/Common/ErrorPage';
import { AppSettings, lowerName } from '@/shared/app-common';
import { GetStaticProps, InferGetStaticPropsType } from 'next';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { NextPageWithLayout } from '@/pages/_app';

export const getStaticProps: GetStaticProps = async (context) => {
  const { locale } = context;
  const localeRes = await serverSideTranslations(locale, ['common']);
  const tempData = localeRes['_nextI18Next']['initialI18nStore'][locale]['common'] ?? {};

  return {
    props: {
      ...localeRes,
      title: tempData[`${lowerName}_meta_Title`],
      description: tempData[`${lowerName}_meta_Desc`],
    },
  };
};

const Custom404: NextPageWithLayout<InferGetStaticPropsType<typeof getStaticProps>> = () => {
  return <ErrorPage />;
};

Custom404.getLayout = function getLayout(page: React.ReactElement) {
  const title = page?.props?.title ?? '';
  const desc = page?.props?.description ?? '';

  return (
    <Layout
      head={
        <Head>
          <title>{title}</title>
          <meta name="title" content={title} />
          <meta name="description" content={desc} />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
        </Head>
      }
    >
      {page}
    </Layout>
  );
};

export default Custom404;
